<script lang="ts">
  import classnames from "classnames"
</script>

<span class="{classnames('brush', $$props.class)}"></span>

<style type="text/scss" lang="scss" scoped>
  .brush {
    @apply inline-block w-24 h-10;
    @apply bg-brush;
  }
</style>
